import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiSolidCricketBall, BiFootball, BiTennisBall } from 'react-icons/bi';
import './UpcomingEvents.css';
// import "../../../src/App.css";
import { TimeContext } from '../TimeContext/TimeContext';

const UpcomingEvents = () => {
  const [currentTime, setCurrentTime] = useState(new Date('2024-08-26T04:30'));
  const scrollRefs = useRef([]); // Array of refs for each event box
  const { setMatchTime } = useContext(TimeContext);

  const navigate = useNavigate();

  const initializeScrollRefs = (index) => {
    if (!scrollRefs.current[index]) {
      scrollRefs.current[index] = React.createRef(); // Initialize the ref for the event box
    }
  };

  const handleScroll = (scrollingRef) => {
    if (scrollingRef?.current) {
      const scrollLeftValue = scrollingRef.current.scrollLeft; // Get the scroll position of the scrolled box
      scrollRefs.current.forEach(ref => {
        if (ref.current && ref !== scrollingRef) {
          ref.current.scrollLeft = scrollLeftValue; // Update the scroll position for all other boxes
        }
      });
    }
  };

  const handleMatchClick = ( time ) => {
    setMatchTime(time);
    navigate('/matchupdates'); // Navigate to MatchUpdates
  };

  useEffect(() => {
    scrollRefs.current.forEach((ref) => {
      if (ref.current) {
        // Add scroll event listener to each event box
        ref.current.addEventListener('scroll', () => handleScroll(ref));
      }
    });

    // Clean up event listeners when component unmounts
    return () => {
      scrollRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener('scroll', () => handleScroll(ref));
        }
      });
    };
  }, []);

  return (
    <div className='main'>
      <ul className='live'>
        <li className='live-li'>LIVE</li> 
        <li>My Bets(0)</li>
      </ul>
 
      {/* Cricket Section */}
      <div className="cricket">
        <div className="header-box">
          <BiSolidCricketBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Cricket</span>
        </div>
        <div className="bet-numbers">
          <div className="box"><span>1</span></div>
          <div className="box"><span>X</span></div>
          <div className="box"><span>2</span></div>
        </div>
      </div>

      {/* Cricket Event Boxes */}
      {[...Array(5)].map((_, i) => {
        const teams = [
          { teamA: 'India', teamB: 'Pakistan' },
          { teamA: 'Australia', teamB: 'England' },
          { teamA: 'South Africa', teamB: 'New Zealand' },
          { teamA: 'Sri Lanka', teamB: 'Bangladesh' },
          { teamA: 'West Indies', teamB: 'Afghanistan' }
        ];

        const teamA = teams[i % teams.length].teamA;
        const teamB = teams[i % teams.length].teamB;

        initializeScrollRefs(i); // Initialize scroll ref for the current event box

        return (
          <div className="event-box" key={i} onClick={() => handleMatchClick(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}>
            <div className="date-time-box" >
              <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
              <span className="date">{currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </div>
            <div className="matches" onClick={handleMatchClick}>
              <div className="a">{teamA} vs {teamB}</div>
            </div>
            <div
              className="event-numbers"
              ref={scrollRefs.current[i]} // Assign the ref to the event box
              onScroll={() => handleScroll(scrollRefs.current[i])} // Handle scrolling
            >
              <div className="box">
                <span>3.9</span>
              </div>
              <div className="box">
                <span>3.55</span>
              </div>
              <div className="box">
                <span>2.16</span>
              </div>
              <div className="box-pink">
                <span>3.95</span>
              </div>
              <div className="box-pink">
                <span>3.6</span>
              </div>
              <div className="box-pink">
                <span>2.18</span>
              </div>
            </div>
          </div>
        );
      })}

      {/* Football Section */}
      <div className="cricket">
        <div className="header-box">
          <BiFootball size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Football</span>
        </div>
        <div className="bet-numbers">
          <div className="box"><span>1</span></div>
          <div className="box"><span>X</span></div>
          <div className="box"><span>2</span></div>
        </div>
      </div>

      {/* Football Event Boxes */}
      {[...Array(4)].map((_, i) => {
        const teams = [
          { teamA: 'Thunder Strikers', teamB: 'Blaze Titans' },
          { teamA: 'Lightning Raiders', teamB: 'Phoenix Warriors' },
          { teamA: 'Iron Giants', teamB: 'Crimson Crushers' },
          { teamA: 'Rampage Rovers', teamB: 'Avalanche United' }
        ];

        const teamA = teams[i % teams.length].teamA;
        const teamB = teams[i % teams.length].teamB;

        initializeScrollRefs(i + 5); // Initialize scroll ref for the current event box

        return (
          <div className="event-box" key={i + 5} onClick={() => handleMatchClick(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}>
            <div className="date-time-box">
              <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
              <span className="date">{currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </div>
            <div className="matches" onClick={handleMatchClick}>
              <div className="a">{teamA} vs {teamB}</div>
            </div>
            <div
              className="event-numbers"
              ref={scrollRefs.current[i + 5]} // Assign the ref to the event box
              onScroll={() => handleScroll(scrollRefs.current[i + 5])} // Handle scrolling
            >
              <div className="box">
                <span>2.7</span>
              </div>
              <div className="box">
                <span>1.5</span>
              </div>
              <div className="box">
                <span>10.8</span>
              </div>
              <div className="box-pink">
                <span>2.3</span>
              </div>
              <div className="box-pink">
                <span>1.28</span>
              </div>
              <div className="box-pink">
                <span>3.2</span>
              </div>
            </div>
          </div>
        );
      })}

      {/* Tennis Section */}
      <div className="cricket">
        <div className="header-box">
          <BiTennisBall size={25} color="orange" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>Tennis</span>
        </div>
        <div className="bet-numbers">
          <div className="box"><span>1</span></div>
          <div className="box"><span>X</span></div>
          <div className="box"><span>2</span></div>
        </div>
      </div>

      {/* Tennis Event Boxes */}
      {[...Array(3)].map((_, i) => {
        const teams = [
          { teamA: 'Ball Busters', teamB: 'Net Nuts' },
          { teamA: 'Deuce Bags', teamB: 'Lob-sters' },
          { teamA: 'Fault Line', teamB: 'Fuzzy Balls' },
        ];

        const teamA = teams[i % teams.length].teamA;
        const teamB = teams[i % teams.length].teamB;

        initializeScrollRefs(i + 9); // Initialize scroll ref for the current event box

        return (
          <div className="event-box" key={i + 9} onClick={() => handleMatchClick(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))}>
            <div className="date-time-box">
              <p>{i % 2 === 0 ? 'Tomorrow' : currentTime.toLocaleDateString()}</p>
              <span className="date">{currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </div>
            <div className="matches" onClick={handleMatchClick}>
              <div className="a">{teamA} vs {teamB}</div>
            </div>
            <div
              className="event-numbers"
              ref={scrollRefs.current[i + 9]} // Assign the ref to the event box
              onScroll={() => handleScroll(scrollRefs.current[i + 9])} // Handle scrolling
            >
              <div className="box">
                <span>1.95</span>
              </div>
              <div className="box">
                <span>2.4</span>
              </div>
              <div className="box">
                <span>4.7</span>
              </div>
              <div className="box-pink">
                <span>3.5</span>
              </div>
              <div className="box-pink">
                <span>2.1</span>
              </div>
              <div className="box-pink">
                <span>1.7</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UpcomingEvents;
