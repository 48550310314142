import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import MainScreen from './components/MainScreen/MainScreen';
import MatchUpdates from './components/MatchUpdates/MatchUpdates';
// import Login from './components/Login/Login';
// import SignUp from './components/SignUp/SignUp';
import AccountStatus from './components/AccountStatus/AccountStatus';
import PlaceBet from './components/PlaceBet/PlaceBet';
// import AfterLogin from './components/AfterLogin/AfterLogin';
import { useSelector } from 'react-redux';
import AccountStatement from './components/AccountStatement/AccountStatement';
import Deposit from './components/Deposit/Deposit';
import Withdraw from './components/Withdraw/Withdraw';
import MyMarkets from './components/MyMarkets/MyMarkets';
import CricketList from './components/CricketList/CricketList';
import FootballList from './components/FootballList/FootballList';
import TennisList from './components/TennisList/TennisList';
import HorseRacingList from './components/HorseRacingList/HorseRacingList';
import GrayHoundRacingList from './components/GrayHoundRacingList/GrayHoundRacingList';
import IndianCardGamesList from './components/IndianCardGamesList/IndianCardGamesList';
import OpenBets from './components/OpenBets/OpenBets';
import ProfitAndLoss from './components/ProfitAndLoss/ProfitAndLoss';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Settings from './components/Settings/Settings';
import Profile from './components/Profile/Profile';
import { FaN } from 'react-icons/fa6';
import FancyMarket from './components/FancyMarket/FancyMarket';
import ProfitAndLoss2 from './components/ProfitAndLoss/ProfitAndLoss2';
import ProfitAndLoss3 from './components/ProfitAndLoss/ProfitAndLoss3';
import Rules from './components/Rules/Rules';
import AddWithdrawDetails from './components/AddWithdrawDetails/AddWithdrawDetails';
import BankForm from './components/AddWithdrawDetails/BankForm';
import PaytmForm from './components/AddWithdrawDetails/PaytmForm';
import UpiForm from './components/AddWithdrawDetails/UpiForm';
import PhonepeForm from './components/AddWithdrawDetails/PhonepeForm';
import GpayForm from './components/AddWithdrawDetails/GpayForm';
import Empty from './components/EmptyDiv/Empty';
import ReferAndEarn from './components/ReferAndEarn/ReferAndEarn';
import { TimeProvider } from './components/TimeContext/TimeContext';
import PlaceBetSuccessfull from './components/PlaceBetSuccesfull/PlaceBetSuccessfull';
import ScrollCasino from './components/ScrollCasino/ScrollCasino';
import UpcomingEvents from './components/UpcomingEvents/UpcomingEvents';
// const App = () => {
  // const isAuthenticated = localStorage.getItem('isAuthenticated'); // Retrieve auth status from localStorage

 function App() {
  // const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <TimeProvider>
    <Router>
        <Routes>
          {/* <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} /> */}
          
          {/* Protected Routes */}
          {/* <Route
            path="/ "
            element={isAuthenticated ? <MainScreen /> : <Navigate to="/login" />}
          />
          <Route
            path="/matchupdates"
            element={isAuthenticated ? <MatchUpdates /> : <Navigate to="/login" />}
          />
          <Route
            path="/accountstatus"
            element={isAuthenticated ? <AccountStatus /> : <Navigate to="/login" />}
          />
          <Route
            path="/placebet"
            element={<PlaceBet />}
          /> */}
          {/* <Route path='/' element={ <AfterLogin/> }/> */}
          <Route path='/' element={ <MainScreen/> } />
          
          <Route path='/matchupdates' element={ <MatchUpdates/> } />
          <Route path='upcomingevents' element= { <UpcomingEvents /> } />
          <Route path='/accountstatus' element={ <AccountStatus/> } />
          <Route path='/placebet' element={ <PlaceBet/> } />
          <Route path='/accountstatement' element={ <AccountStatement/> } />
          <Route path='/deposit' element={ <Deposit/> } />
          <Route path='/withdraw' element={ <Withdraw/> } />
          <Route path='/mymarkets' element={ <MyMarkets/> } />
          <Route path='/cricketlist' element={ <CricketList/> } />
          <Route path='/football-list' element={ <FootballList/> } />
          <Route path='/tennislist' element={ <TennisList/> } />
          <Route path='/horseracinglist' element={ <HorseRacingList/> } />
          <Route path='/grayhoundracinglist' element={ <GrayHoundRacingList/> } />
          <Route path='/indiancardgameslist' element={ <IndianCardGamesList/> } />
          <Route path='/openbets' element={ <OpenBets/> } />  
          <Route path='/profitandloss' element={ <ProfitAndLoss/> } />
          <Route path='/changepassword' element={ <ChangePassword/> } />
          <Route path='/settings' element={ <Settings/> } />
          <Route path='/profile' element={ <Profile /> } />
          <Route path='/fancymarket' element={ <FancyMarket/> } />
          <Route path='/profitandloss2' element={<ProfitAndLoss2 />} />
          <Route path='/profitandloss3' element={<ProfitAndLoss3 />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/addwithdrawdetails' element={<AddWithdrawDetails />} />
          <Route path='/bankform' element={<BankForm />} />
          <Route path='/paytmform' element={<PaytmForm />} />
          <Route path='/upiform' element={<UpiForm />} />
          <Route path='/phonepeform' element={<PhonepeForm />} />
          <Route path='/gpayform' element={<GpayForm />} />
          <Route path='/emptydiv' element={<Empty />} />
          <Route path='/referandearn' element={<ReferAndEarn />} />
          <Route path='/placebetsuccessfully' element={<PlaceBetSuccessfull />} />
          <Route path='/scrollcasino' element={<ScrollCasino />} />
        </Routes>
    </Router>
          </TimeProvider>
  );
 }
// };

export default App;
