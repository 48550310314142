import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AccountStatus.css';
// import "../../../src/App.css";
import { TbCoin } from "react-icons/tb";
import { MdOutlineReplay, MdOutlineSettings, MdAccountBalanceWallet } from "react-icons/md";
import { GiWallet } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import { IoReorderThree } from "react-icons/io5";
import { GrCurrency } from "react-icons/gr";
import { RiPagesLine } from "react-icons/ri";
import { FaBook, FaLock } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { LuLogOut } from "react-icons/lu";
import { ImProfile } from "react-icons/im";
import LoginPopup from '../LoginPopup/LoginPopup';

const AccountStatus = ({ handleClose }) => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated'); 
    localStorage.removeItem('userToken'); 
    handleClose(); 
    navigate('/login');
  };

  // Function to navigate to the given path
  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className='main-section'>
      <div className="section-second">
        <nav className="navbar-account">
          <button type="button" className="btn btn-dark bet">
            <TbCoin size={25} color="white" style={{ marginRight: 0 }} />Bets
          </button>
          {/* <button type="button" className="btn btn-dark replay">
            <MdOutlineReplay size={25} color="white" style={{ marginRight: 0 }} />
          </button> */}
          <button type="button" className="btn btn-dark setting" onClick={() => navigateTo('/settings')}>
            <MdOutlineSettings size={25} color="white" style={{ marginRight: 0 }} />
          </button>
        </nav>
        <nav className="navbar-small">
          <button className="button-83" role="button" onClick={() => navigateTo('/deposit')}>
            Deposit <MdAccountBalanceWallet size={25} color="white" style={{ marginRight: 0, marginLeft: 0 }} />
          </button>
          <button className="withdraw" role="button" onClick={() => navigateTo('/withdraw')}>
            Withdraw <GiWallet size={25} color="white" style={{ marginRight: 0 }} />
          </button>
        </nav>
        <div className="vertical-navbar">
          <ul>
            <li><button className='ac-button' onClick={() => navigateTo('/openbets')}><IoReorderThree className='icon-button' /> Open Bets</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/profitandloss')}><GrCurrency className='icon-button' /> Profit And Loss</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/accountstatement')}><RiPagesLine className='icon-button' /> Account Statement</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/profile')}><ImProfile className='icon-button' /> My Profile</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/referandearn')}><ImProfile className='icon-button' /> Refer & Earn</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/rules')}><FaBook className='icon-button' /> Rules</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/changepassword')}><FaLock className='icon-button' /> Change Password</button></li>
            <li><button className='ac-button' onClick={() => navigateTo('/settings')}><IoMdSettings className='icon-button' /> Settings</button></li>
          </ul>
        </div>
        {showLoginPopup && <LoginPopup onClose={() => setShowLoginPopup(false)} />}
        <button type="button" className="btn btn-danger account" onClick={handleLogout}>
          Logout <LuLogOut size={25} color="white" style={{ marginLeft: 10 }} />
        </button>
      </div>
    </div>
  );
};

export default AccountStatus;
