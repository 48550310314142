import React, { useState, useContext } from "react";
import "./MatchUpdates.css";
import "../PlaceBet/PlaceBet.css";
// import "../../../src/App.css";
import { IoMdClose } from "react-icons/io"; // Importing close icon
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaPlayCircle } from "react-icons/fa";
import { MdLiveTv } from "react-icons/md";
import FancyMarket from "../FancyMarket/FancyMarket";
import PlaceBetSuccessfull from "../PlaceBetSuccesfull/PlaceBetSuccessfull";
import Empty from "../EmptyDiv/Empty";
import { TimeContext } from "../TimeContext/TimeContext";


const MatchUpdates = () => {
  const [showPlaceBetPopup, setShowPlaceBetPopup] = useState(false);
  const [betType, setBetType] = useState(null);
  const [amount, setAmount] = useState(0); // State for amount in PlaceBet popup
  const [showIframe, setShowIframe] = useState(false); // State to manage iframe visibility
  const [showMatchStatus, setShowMatchStatus] = useState(true); // State for Match Status visibility
  const [showOpenBets, setShowOpenBets] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success message popup
  const [betAmount, setBetAmount] = useState(false);
  const { matchTime } = useContext(TimeContext);
  const [betSource, setBetSource] = useState(""); // State to track if it's from 'Match Odds' or 'Bookmaker'
  const [teamName, setTeamName] = useState(""); // State for team name


  const handlePlaceBackClick = (value, team, source) => {
    setBetType("back");
    setAmount(value);
    setTeamName(team);
    setBetSource(source); // Set the source (Match Odds or Bookmaker)
    setShowPlaceBetPopup(true);
  };

  const handlePlaceLayClick = (value, team, source) => {
    setBetType("lay");
    setAmount(value);
    setTeamName(team);
    setBetSource(source); // Set the source (Match Odds or Bookmaker)
    setShowPlaceBetPopup(true);
  };

  const handleClosePlaceBet = () => {
    setShowPlaceBetPopup(false);
  };

  const handleLiveClick = (e) => {
    e.preventDefault();
    setShowIframe((prevShowIframe) => !prevShowIframe);
  };

  const handlePriceClick = (price) => {
    setBetAmount(price);
  };

  const handleMarketClick = () => {
    setShowMatchStatus(true); // Show match status
    setShowOpenBets(false); // Hide open bets
    setShowIframe(false); // Hide iframe
  };

  const handleOpenBets = () => {
    setShowOpenBets(true); // Toggle Open Bets visibility
    setShowMatchStatus(false); // Hide match status when open bets are shown
  };

  const handlePlaceBet = () => {
    // Show the success popup
    setShowSuccessPopup(true);
    // Close the place bet popup
    setShowPlaceBetPopup(false);
  };

  return ( 
    <div>
      <Header />
      <div className="match-update-header">
        <div className="inside-content">
          <FaPlayCircle size={15} color="white" style={{ marginRight: 5, marginLeft: 5, marginTop: 3 }} />
          <span>India vs Pakistan</span>
          <p>{matchTime ? matchTime : "Match Time Not Set"}</p>
        </div>
        <IoInformationCircleOutline size={15} color="white" style={{ marginRight: 5, marginLeft: 0, marginTop: 10 }} />
      </div>

      <div className="header-part-2">
        <a href="#" onClick={handleMarketClick}>MARKET</a>
        <a href="#" onClick={handleLiveClick}>
          LIVE <MdLiveTv size={15} color="white" style={{ marginBottom: 2, marginLeft: 2 }} />
        </a>
        <a href="#" onClick={handleOpenBets}>OPEN BETS</a>
      </div>

      {showIframe && (
        <iframe
          src="https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik"
          width="100%"
          height="200px"
          title="Live Match"
          style={{ border: "none", marginTop: "10px" }}
        />
      )}

      {showOpenBets && (
        <div className="open-bets-section">
          <h2>Open Bets</h2>
          <p>No open bets available.</p>
        </div>
      )}

      {showMatchStatus && (
        <div className="match-status">
          <div className="match-status-heading">
            <div className="match-odds-heading">
              <span>Match Odds</span>
            </div>
            <div className="back-lay">
              <span className='black'>Back</span>
              <span className='lay'>Lay</span>
            </div>
          </div>
          <div className="match-details">
            <div className="team-name-box">
              <span className='team-name'>India</span>
              <div className="team-number">
                <span className='team-back-number' onClick={() => handlePlaceBackClick(17.5, "India", "Match Odds")}>17.5</span>
                <span className='team-lay-number' onClick={() => handlePlaceLayClick(17.5, "India", "Match Odds")}>17.5</span>
              </div>
            </div>
            <div className="team-name-box">
              <span className='team-name'>Pakistan</span>
              <div className="team-number">
                <span className='team-back-number' onClick={() => handlePlaceBackClick(20, "Pakistan", "Match Odds")}>20</span>
                <span className='team-lay-number' onClick={() => handlePlaceLayClick(20, "Pakistan", "Match Odds")}>20</span>
              </div>
            </div>
            <div className="team-name-box">
              <span className='team-name'>The Draw</span>
              <div className="team-number">
                <span className='team-back-number' onClick={() => handlePlaceBackClick(15, "The Draw", "Match Odds")}>15</span>
                <span className='team-lay-number' onClick={() => handlePlaceLayClick(15, "The Draw", "Match Odds")}>15</span>
              </div>
            </div>
          </div>
        </div>
      )}


<div className="match-status">
  <div className="match-status-heading-toss">
    <span>To Win The Toss</span>
  </div>
  <div className="match-details">
    <div className="team-toss-box">
      <span
        className="team-toss A"
       
      >
        India
      </span>
      <span
        className="team-toss B"
      >
        Pakiastan
      </span>
    </div>
    <div className="toss-box-number">
      <div className="toss-box" onClick={() => handlePlaceBackClick(98, "India", "To Win The Toss")}>
        <span>98</span>
      </div>
      <div className="toss-box" onClick={() => handlePlaceBackClick(98, "Pakistan", "To Win The Toss")}>
        <span>98</span>
      </div>
    </div>
  </div>
</div>

<div className="match-status">
  <div className="match-status-heading">
    <span>Bookmaker</span>
    <div className="back-lay">
      <span className="black">Back</span>
      <span className="lay">Lay</span>
    </div>
  </div>
  <div className="match-details">
    <div className="team-name-box">
      <span className="team-name">India</span>
      <div className="team-number">
        <span className="team-back-number" onClick={() => handlePlaceBackClick(17.5, "India", "Bookmaker")}>17.5</span>
        <span className="team-lay-number" onClick={() => handlePlaceLayClick(17.5, "India", "Bookmaker")}>17.5</span>
      </div>
    </div>
    <div className="team-name-box">
      <span className="team-name">Pakistan</span>
      <div className="team-number">
        <span className="team-back-number" onClick={() => handlePlaceBackClick(20, "Pakistan", "Bookmaker")}>17.5</span>
        <span className="team-lay-number" onClick={() => handlePlaceLayClick(20, "Pakistan", "Bookmaker")}>17.5</span>
      </div>
    </div>
    <div className="team-name-box">
      <span className="team-name">The Draw</span>
      <div className="team-number">
        <span className="team-back-number" onClick={() => handlePlaceBackClick(15, "The Draw", "Bookmaker")}>17.5</span>
        <span className="team-lay-number" onClick={() => handlePlaceLayClick(15, "The Draw", "Bookmaker")}>17.5</span>
      </div>
    </div>
  </div>
</div>

{showPlaceBetPopup && (
  <div className="modal-overlay" onClick={handleClosePlaceBet}>
    <div
      className="modal-content"
      onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal content
    >
      <PlaceBet 
        betType={betType} 
        initialAmount={amount} 
        onClose={handleClosePlaceBet} 
        onPlaceBet={handlePlaceBet} 
        teamName={teamName}   // Pass the teamName prop
        betSource={betSource} // Pass the betSource prop
      />
    </div>
  </div>
)}


{showSuccessPopup && (
  <PlaceBetSuccessfull 
    message="Bet placed successfully!" 
    onClose={() => setShowSuccessPopup(false)} // Close the success popup
  />
)}

<div className="match-status">
  <div className="fancy-market">
    <div className="match-status-heading">
      <span>Fancy</span>
    </div>
    <FancyMarket />
  </div>
</div>



      <Empty />

      <Footer />
    </div>
  );
};

const PlaceBet = ({ betType, initialAmount = 0, onClose, onPlaceBet, teamName, betSource, matchOdds }) => {
  const [betAmount, setBetAmount] = useState(initialAmount); // Initialize betAmount with the initialAmount prop
  const [price, setPrice] = useState("0");
  // Update betAmount when initialAmount changes
  React.useEffect(() => {
    setBetAmount(initialAmount);
  }, [initialAmount]);

  const handleIncrease = () => {
    setPrice((prevAmount) => prevAmount + 1);
  };

  const handleDecrease = () => {
    setPrice((prevAmount) => (prevAmount > 0 ? prevAmount - 1 : 0));
  };

  const handlePriceClick = (price) => {
    setPrice(price);
  };

  const handlePlaceClick = () => {
    // Call the function to place the bet with the selected team and odds
    onPlaceBet({ teamName, betAmount, matchOdds, betType }); 
  };


  // Dynamic background based on betType
  const backgroundColor = betType === "back" ? "rgb(179, 209, 235)" : "#ecb7de";

  return (
    <div className="placebet-container" style={{ backgroundColor }}>
      <div className="placebet-header">
        <IoMdClose className="close-icon" onClick={onClose} color="black" style={{ marginTop: 10, marginRight: 10 }} />
      </div>
      <div className="placebet-description">
        <span>
          <p>{betSource}</p> {/* Display betSource */}
          <p>{betType === "back" ? "Back" : "Lay"}: {teamName}</p> {/* Display teamName */}
          {/* <p>Match Odds: {matchOdds}</p> Display match odds */}
        </span>
      </div>
      <div className="placebet-boxes">
        <div className="rate-box">
          {/* Rate/Odds input box */}
          <div className="inc-dec-button">
            <button id="decrease" className="btn">-</button>
            <input
              type="number"
              value={betAmount} // Display the match odds value in this input field
              readOnly // Make it read-only
            />
            <button id="increase" className="btn">+</button>
          </div> 
          {/* Bet amount input box */}
          <div className="input-number-box">
            <button id="decrease" className="btn" onClick={handleDecrease}>
              -
            </button>
            <input
              type="number"
              value={price} // Display betAmount in this input field
              min="0"
              onChange={(e) => setBetAmount(parseFloat(e.target.value))} // Allow user to edit the amount
            />
            <button id="increase" className="btn" onClick={handleIncrease}>
              +
            </button>
          </div>
        </div>
      </div>
      {/* Price buttons */}
      <div className="price-boxes">
        {[500, 2000, 5000, 10000, 15000, 20000].map((price) => (
          <button key={price} className="price-box-1" onClick={() => handlePriceClick(price)}>
            {price}
          </button>
        ))}
      </div>
      {/* Action buttons */}
      <div className="action-buttons">
        <button className="cancel-btn" onClick={onClose}>
          Cancel
        </button>
        <button className="placebet-btn" onClick={handlePlaceClick}>
          Place Bet
        </button>
      </div>
    </div>
  );
};





export default MatchUpdates;
