import React, { useState } from "react";
import "./FancyMarket.css";
import "../PlaceBet/PlaceBet.css";
// import "../../../src/App.css";
import PlaceBetSuccessfull from "../PlaceBetSuccesfull/PlaceBetSuccessfull";
import { IoMdClose } from "react-icons/io"; // Importing close icon

const FancyMarket = () => {
  const [showPlaceBetPopup, setShowPlaceBetPopup] = useState(false);
  const [betType, setBetType] = useState(null);
  const [amount, setAmount] = useState(0); // State for amount in PlaceBet popup
  const [showMatchStatus, setShowMatchStatus] = useState(true); // State for Match Status visibility
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success message popup
  const [betValue, setBetValue] = useState(null); // Yeh state value ko store karegi
  const [selectedTeamName, setSelectedTeamName] = useState(""); // State to hold the selected team name
  const handlePlaceBackClick = (value, teamName) => {
    setBetType("back");
    setAmount(value);
    setShowPlaceBetPopup(true);
    setBetValue(value);
    setSelectedTeamName(teamName); // Set the selected team name
  };

  const handlePlaceLayClick = (value, teamName) => {
    setBetType("lay");
    setAmount(value);
    setShowPlaceBetPopup(true);
    setBetValue(value);
    setSelectedTeamName(teamName); // Set the selected team name
  };

  const handleClosePlaceBet = () => {
    setShowPlaceBetPopup(false);
  };

  const handlePlaceBet = () => {
    // Show the success popup
    setShowSuccessPopup(true);
    // Close the place bet popup
    setShowPlaceBetPopup(false);
  };

  return (
    <div>
      {showMatchStatus && (
        <div className="fancy-match-details">
          <div className="team-name-box fancy-box">
            <span className="team-name">1st wicket IND</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(175, "1st wicket IND")}
              >
                175<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(170, "1st wicket IND")}
              >
                170<p>100</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">1 over IND</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(108, "1 over IND")}
              >
                108<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(107, "1 over IND")}
              >
                107<p>100</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">5 over IND</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(130, "5 over IND")}
              >
                130<p>125</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(130, "5 over IND")}
              >
                130<p>105</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">V Kohli Odd Run Bhav</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(200, "V Kohli Odd Run Bhav")}
              >
                200<p>150</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(250, "V Kohli Odd Run Bhav")}
              >
                250<p>108</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">3rd wicket Ind</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(102, "3rd wicket Ind")}
              >
                102<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(105, "3rd wicket Ind")}
              >
                105<p>104</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">8 over IND</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(109, "8 over IND")}
              >
                109<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(110, "8 over IND")}
              >
                110<p>100</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">R Pant</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(80, "R Pant")}
              >
                80<p>120</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(80, "R Pant")}
              >
                85<p>100</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">6th wicket caught out</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(1, "6th wicket caught out")}
              >
                1<p>80</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(1, "6th wicket caught out")}
              >
                1<p>60</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">R Pant Odd Run Bhav</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(1, "R Pant Odd Run Bhav")}
              >
                1<p>103</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(1, "R Pant Odd Run Bhav")}
              >
                1<p>97</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">V Kohli Boundaries 1</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(2, "V Kohli Boundaries 1")}
              >
                2<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(3, "V Kohli Boundaries 1")}
              >
                3<p>100</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">R Pant Single Run Bhav</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(1, "R Pant Single Run Bhav")}
              >
                1<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(2, "R Pant Single Run Bhav")}
              >
                2<p>100</p>
              </span>
            </div>
          </div>
          <div className="team-name-box fancy-box">
            <span className="team-name">IND Total Odd Run</span>
            <div className="team-number">
              <span
                className="team-back-number"
                onClick={() => handlePlaceBackClick(108, "IND Total Odd Run")}
              >
                108<p>100</p>
              </span>
              <span
                className="team-lay-number"
                onClick={() => handlePlaceLayClick(175, "IND Total Odd Run")}
              >
                175<p>100</p>
              </span>
            </div>
          </div>
        </div>
      )}

      

{showPlaceBetPopup && (
  <div className="modal-overlay">
    <div className="modal-content">
      <PlaceBet 
        betType={betType} 
        initialAmount={amount} 
        onClose={handleClosePlaceBet} 
        onPlaceBet={handlePlaceBet} 
        teamName={selectedTeamName} // Pass the team name
      />
    </div>
  </div>
)}

      {showSuccessPopup && (
        <PlaceBetSuccessfull
          message="Bet placed successfully!"
          onClose={() => setShowSuccessPopup(false)} // Close the success popup
        />
      )}
    </div>
  );
};

const PlaceBet = ({
  betType,
  initialAmount = 0,
  onClose,
  onPlaceBet,
  teamName,
  betSource,
  matchOdds,
}) => {
  const [betAmount, setBetAmount] = useState(initialAmount); // Initialize betAmount with the initialAmount prop
  const [price, setPrice] = useState("0");
  // Update betAmount when initialAmount changes
  React.useEffect(() => {
    setBetAmount(initialAmount);
  }, [initialAmount]);

  const handleIncrease = () => {
    setPrice((prevAmount) => prevAmount + 1);
  };

  const handleDecrease = () => {
    setPrice((prevAmount) => (prevAmount > 0 ? prevAmount - 1 : 0));
  };

  const handlePriceClick = (price) => {
    setPrice(price);
  };

  const handlePlaceClick = () => {
    // Call the function to place the bet with the selected team and odds
    onPlaceBet({ teamName, betAmount, matchOdds, betType });
  };

  // Dynamic background based on betType
  const backgroundColor = betType === "back" ? "rgb(179, 209, 235)" : "#ecb7de";

  return (
    <div className="placebet-container" style={{ backgroundColor }}>
      <div className="placebet-header">
        <IoMdClose
          className="close-icon"
          onClick={onClose}
          color="black"
          style={{ marginTop: 10, marginRight: 10 }}
        />
      </div>
      <div className="placebet-description">
        <span>
          {/* <p>{teamName}</p> Display betSource */}
          <p>
            {betType === "back" ? "Back" : "Lay"}: {teamName}
          </p>{" "}
          {/* Display teamName */}
          {/* <p>Match Odds: {matchOdds}</p> Display match odds */}
        </span>
      </div>
      <div className="placebet-boxes">
        <div className="rate-box">
          {/* Rate/Odds input box */}
          <div className="inc-dec-button">
            <button id="decrease" className="btn">
              -
            </button>
            <input
              type="number"
              value={betAmount} // Display the match odds value in this input field
              readOnly // Make it read-only
            />
            <button id="increase" className="btn">
              +
            </button>
          </div>
          {/* Bet amount input box */}
          <div className="input-number-box">
            <button id="decrease" className="btn" onClick={handleDecrease}>
              -
            </button>
            <input
              type="number"
              value={price} // Display betAmount in this input field
              min="0"
              onChange={(e) => setBetAmount(parseFloat(e.target.value))} // Allow user to edit the amount
            />
            <button id="increase" className="btn" onClick={handleIncrease}>
              +
            </button>
          </div>
        </div>
      </div>
      {/* Price buttons */}
      <div className="price-boxes">
        {[500, 2000, 5000, 10000, 15000, 20000].map((price) => (
          <button
            key={price}
            className="price-box-1"
            onClick={() => handlePriceClick(price)}
          >
            {price}
          </button>
        ))}
      </div>
      {/* Action buttons */}
      <div className="action-buttons">
        <button className="cancel-btn" onClick={onClose}>
          Cancel
        </button>
        <button className="placebet-btn" onClick={handlePlaceClick}>
          Place Bet
        </button>
      </div>
    </div>
  );
};

export default FancyMarket;
