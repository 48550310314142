import React, { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Button } from 'react-bootstrap';
import { FaDice, FaHorse, FaHorseHead, FaRegEye, FaRocket, FaTrophy, FaUser } from "react-icons/fa";
import { BiCricketBall, BiFootball, BiSolidTennisBall } from "react-icons/bi";
import { ImSpades } from 'react-icons/im';
import { PiMonitorPlay } from 'react-icons/pi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
// import "../../../src/App.css";
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import LoginPopup from '../LoginPopup/LoginPopup';
import AccountStatus from '../AccountStatus/AccountStatus';
import { useNavigate } from 'react-router-dom';


const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false); // Right slider
  const [showMenu, setShowMenu] = useState(false); // Left slider
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showMyMarkets, setShowMyMarkets] = useState(false);
  const [showCricketList, setShowCricketList] = useState(false);
  const [showFootballList, setShowFootballList] = useState(false);
  const [showTennisList, setShowTennisList] = useState(false);
  const [showHorseRacingList, setShowHorseRacingList] = useState(false);
  const [showGrayHoundRacingList, setShowGrayHoundRacingList] = useState(false);
  const [showIndianCardGamesList, setShowIndianCardGamesList] = useState(false);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    if (showOffcanvas) {
      setShowOffcanvas(false); // Close the right slider if it's open
    }
    setShowMenu(!showMenu);
  };

  const handleUserClick = () => {
    if (showMenu) {
      setShowMenu(false); // Close the left slider if it's open
    }
    setShowOffcanvas(true);
  };

  const handleClose = () => {
    setShowOffcanvas(false);
  };

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  const handleDeposit = () => {
    setShowDeposit(true);
    navigate('/deposit');
  }
  const handleMyMarkets = () => {
    setShowMyMarkets(true);
    navigate('/mymarkets');
  }

  const handleCricketList = () => {
    setShowCricketList(true);
    navigate('/cricketlist');
  }
  const handleFootballList = () => {
    setShowFootballList(true);
    navigate('/football-list');
  }
  const handleTennisList = () => {
    setShowTennisList(true);
    navigate('/tennislist');
  }
  const handleHorseRacingList = () => {
    setShowHorseRacingList(true);
    navigate('/horseracinglist');
  }
  const handleGrayHoundRacingList = () => {
    setShowGrayHoundRacingList(true);
    navigate('/grayhoundracinglist');
  }
  const handleIndianCardGamesList = () => {
    setShowIndianCardGamesList(true);
    navigate('/indiancardgameslist');
  } 

  return (
    <>
      <Navbar variant="dark" expand="lg" fixed="top" className="sticky-header">
        <div className="d-flex align-items-center">
          {/* Hamburger Menu Button on the Left Side */}
          <Button
            className={`navbar-toggler me-2 hamburger ${showMenu ? 'open' : ''}`}
            type="button"
            onClick={handleToggleMenu}
            aria-controls="navbarSupportedContent"
            aria-expanded={showMenu}
            aria-label="Toggle navigation" 
            variant="dark">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </Button>
          <NavLink className="navbar-brand" to="/"><img className='header-logo' src="../Images/logo.png" alt=''/></NavLink>
          <div className="user-section">
            <button class="button-30" role="button" onClick={handleDeposit}>Deposit</button>
            <button onClick={handleUserClick} className='user-button'>
              <span className='user-name'>User</span>
              <FaUser size={15} color="white" style={{ marginLeft: 1, marginRight: 1, marginBottom: 5 }} className='fa-user'/>
            </button>
          </div> 
        </div>
      </Navbar>

      {/* Offcanvas menu for mobile (Left Slider) */}
      <Offcanvas show={showMenu} onHide={handleToggleMenu} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Nav className="flex-column">
             <ul className="navbar-nav mr-auto">
             <li className="nav-item active">
               <button className="nav-link" onClick={handleMyMarkets}><FaRegEye size={30} color="black" style={{ marginRight: 10 }}   />My markets</button>
             </li>
             <li className="nav-item">
               <button className="nav-link"><FaRocket size={30} color="Orange" style={{ marginRight: 10 }}  />Aviator</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleCricketList}><BiCricketBall size={30} color="#900C3F" style={{ marginRight: 10 }}  />Cricket</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleFootballList}><BiFootball size={30} color="black" style={{ marginRight: 10 }}  />Football</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleTennisList}><BiSolidTennisBall size={30} color="green" style={{ marginRight: 10 }}  />Tennis</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleHorseRacingList}><FaHorseHead size={30} color="black" style={{ marginRight: 10 }}  />Horce Racing</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleGrayHoundRacingList}><FaHorse size={30} color="brown" style={{ marginRight: 10 }}  />Grayhound Racing</button>
             </li>
             <li className="nav-item">
               <button className="nav-link" onClick={handleIndianCardGamesList}><ImSpades size={30} color="black" style={{ marginRight: 10 }}  />Indian Card Games</button>
             </li>
             <li className="nav-item">
               <button className="nav-link"><FaTrophy size={30} color="#ffd133" style={{ marginRight: 10 }}  />Sportsbook (80+)</button>
             </li>
             <li className="nav-item">
               <button className="nav-link"><PiMonitorPlay size={30} color="black" style={{ marginRight: 10 }}  />Casino</button>
             </li>
             <li className="nav-item">
               <button className="nav-link"><FaDice size={30} color="red" style={{ marginRight: 10 }}  />Slot Games</button>
             </li>
           </ul>
          </Nav>
          {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Fullscreen Offcanvas for Account Status (Right Slider) */}
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" className="offcanvas-fullscreen">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Account Status</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AccountStatus handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
